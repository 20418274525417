@import '../variables.scss';

.contactFormWrapper {
  position: relative;
  width: 65%;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-bottom: 5.723vw;
  opacity: 0;
  transition: opacity 0.8s ease;
  scroll-margin-top: 75px;
  @media (max-width: $breakpoint-md) {
    width: 90%;
    justify-content: center;
    padding-bottom: 30px;
  }

  &.visible {
    opacity: 1;

    form {
      .formItem {
        transform: translateY(0);
      }
    }

    button {
      transform: translateY(0);
    }
  }

  &.secondDefault {
    form {
      input {
        position: relative;
        padding: 1.386vw 1.807vw 0.723vw 1.807vw;
        border-bottom: 0.12vw solid $title-variation-2;
        height: 4.06vw;
        border-radius: 0.301vw 0.301vw 0 0;
        @media (max-width: $breakpoint-md) {
          border-bottom: 2px solid $title-variation-2;
          padding: 23px 30px 12px 20px;
          height: 58px;
          border-radius: 5px 5px 0 0;
        }
        @media (min-width: $breakpoint-lg) {
          padding: 23px 30px 12px 25px;
          height: 75px;
          border-bottom: 2px solid $title-variation-2;
          border-radius: 5px 5px 0 0;
        }
      }

      textarea {
        padding: 1.386vw 1.807vw 0.723vw 1.807vw;
        border-bottom: 0.12vw solid $title-variation-2;
        border-radius: 0.301vw 0.301vw 0 0;
        @media (max-width: $breakpoint-md) {
          border-bottom: 2px solid $title-variation-2;
          padding: 39px 0 0 21px;
          border-radius: 5px 5px 0 0;
        }
        @media (min-width: $breakpoint-lg) {
          padding: 2.048vw 0 0 30px;
          border-bottom: 2px solid $title-variation-2;
          border-radius: 5px 5px 0 0;
        }
      }

      // :nth-child(2) {
      //   input {
      //     background-image: url('../../../public/images/chevron-blue.svg');
      //   }
      // }

      .formItem {
        @include FONT-REGULAR;

        &:first-child {
          z-index: 200;
        }

        &:nth-child(3) {
          z-index: 100;
        }

        div[class*='_control'] {
          padding: 1.386vw 1.807vw 0.723vw 1.807vw;
          height: 4.06vw;
          border-bottom: 0.12vw solid $title-variation-2;
          @media (max-width: $breakpoint-md) {
            padding: 12px 16px;
            height: 58px;
            border-bottom: 2px solid $title-variation-2;
          }
          @media (min-width: $breakpoint-lg) {
            padding: 23px 30px 12px 25px;
            height: 75px;
            border-bottom: 2px solid $title-variation-2;
          }
        }

        div[class*='_menu'] {
          width: 100%;
        }

        .checkboxPlaceholder {
          padding: 1.386vw 1.807vw 0.723vw 1.807vw;
          border-bottom: 0.12vw solid $title-variation-2;
          height: 4.06vw;
          @media (max-width: $breakpoint-md) {
            padding: 12px 16px;
            border-bottom: 2px solid $title-variation-2;
            height: 58px;
          }
          @media (min-width: $breakpoint-lg) {
            padding: 23px 30px 12px 25px;
            height: 75px;
          }
        }

        .namePlaceholder,
        .emailPlaceholder {
          color: $black;
          top: 1.5vw;
          left: 1.807vw;
          @media (max-width: $breakpoint-md) {
            top: 15px;
            left: 20px;
          }
          @media (min-width: $breakpoint-lg) {
            top: 20px;
            left: 25px;
          }
        }

        .msgPlaceholder {
          color: $black;
          top: 1.5vw;
          left: 1.807vw;
          @media (max-width: $breakpoint-md) {
            top: 25px;
            left: 16px;
          }
          @media (min-width: $breakpoint-lg) {
            top: 2.048vw;
            left: 30px;
          }
        }

        .namePlaceholderAct {
          transform: translate(0, -0.7vw) scale(0.8);
          @media (max-width: $breakpoint-md) {
            transform: translate(0, -10px) scale(0.8);
          }
          @media (min-width: $breakpoint-lg) {
            transform: translate(0, -15px) scale(0.8);
          }
        }

        .msgPlaceholderAct {
          transform: translate(0, -1.1vw) scale(0.8);
          @media (max-width: $breakpoint-md) {
            transform: translate(0, -10px) scale(0.8);
          }
          @media (min-width: $breakpoint-lg) {
            transform: translate(0, -25px) scale(0.8);
          }
        }

        .emailPlaceholderAct {
          transform: translate(0, -0.7vw) scale(0.8);
          @media (max-width: $breakpoint-md) {
            transform: translate(0, -10px) scale(0.8);
          }
          @media (min-width: $breakpoint-lg) {
            transform: translate(0, -15px) scale(0.8);
          }
        }

        &:focus-within > .emailPlaceholder {
          transform: translate(0, -0.7vw) scale(0.8);
          @media (max-width: $breakpoint-md) {
            transform: translate(0, -10px) scale(0.8);
          }
          @media (min-width: $breakpoint-lg) {
            transform: translate(0, -15px) scale(0.8);
          }
        }

        &:focus-within > .namePlaceholder {
          transform: translate(0, -0.7vw) scale(0.8);
          @media (max-width: $breakpoint-md) {
            transform: translate(0, -10px) scale(0.8);
          }
          @media (min-width: $breakpoint-lg) {
            transform: translate(0, -15px) scale(0.8);
          }
        }

        &:focus-within > .msgPlaceholder {
          transform: translate(0, -1.1vw) scale(0.8);
          @media (max-width: $breakpoint-md) {
            transform: translate(0, -10px) scale(0.8);
          }
          @media (min-width: $breakpoint-lg) {
            transform: translate(0, -25px) scale(0.8);
          }
        }
      }
    }
  }

  form {
    width: 75%;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    @media (max-width: $breakpoint-md) {
      flex-flow: column nowrap;
    }
    @media (min-width: $breakpoint-lg) {
      max-width: 1660px;
    }

    // :nth-child(2) {
    //   input {
    //     background-image: url('/assets/images/chevron-down.png');
    //     background-position: right 1.084vw bottom 1.084vw;
    //     background-repeat: no-repeat;
    //     background-size: 0.723vw;
    //     @media (max-width: $breakpoint-md) {
    //       background-position: right 26px bottom 18px;
    //       background-size: 12px;
    //     }
    //   }
    // }

    .formItem {
      width: 100%;
      margin-bottom: 1.205vw;
      margin-top: 0.904vw;
      position: relative;
      transform: translateY(30px);
      transition: transform 0.8s ease;

      input:not([type='checkbox']):focus-visible,
      textarea:focus-visible,
      .selectStyle:has(:focus-visible),
      .selectOptions:has(:focus-visible) {
        outline: 2px solid black;
        outline-offset: 2px;
      }

      @media (max-width: $breakpoint-md) {
        margin-bottom: 20px;
        margin-top: 15px;
      }

      .selectStyle {
        @include FONT-REGULAR;
      }

      .selectOptions {
        display: flex;
        justify-content: flex-start;
        overflow: hidden;
        @include FONT-REGULAR;
        align-items: center;
        max-height: 2.108vw;
        @media (max-width: $breakpoint-md) {
          max-height: 35px;
        }
        @media (min-width: $breakpoint-lg) {
          max-height: 35px;
        }

        &:last-child {
          max-height: 2.711vw;
          border-bottom: 0.12vw solid $title-variation-2;
          padding-bottom: 0.602vw;
          @media (max-width: $breakpoint-md) {
            max-height: 45px;
            padding-bottom: 10px;
            border-bottom: 2px solid $title-variation-2;
          }
          @media (min-width: $breakpoint-lg) {
            max-height: 45px;
            padding-bottom: 10px;
            border-bottom: 2px solid $title-variation-2;
          }
        }

        input {
          flex: 0 1 20px;
          margin-right: 0.602vw;
          @media (max-width: $breakpoint-md) {
            margin-right: 10px;
          }
          @media (min-width: $breakpoint-lg) {
            margin-right: 10px;
          }
        }
      }

      &.halfWidth {
        width: 45%;
        margin: 0.904vw 0;
        @media (max-width: $breakpoint-md) {
          margin: 15px 0;
          width: 100%;
        }
      }

      .error {
        @include FONT-REGULAR;
        color: $red;
      }

      span {
        display: block;
        color: $red;
      }

      .namePlaceholder,
      .emailPlaceholder {
        position: absolute;
        color: $red;
        font-family: $font-sans;
        top: 1.5vw;
        left: 1.807vw;
        transition: all 0.3s ease;
        transform-origin: top left;
        pointer-events: none;
        font-size: 0.964vw;
        line-height: 1.265vw;
        @media (max-width: $breakpoint-md) {
          top: 25px;
          left: 20px;
          font-size: 16px;
          line-height: 24px;
        }
        @media (min-width: $breakpoint-lg) {
          font-size: 16px;
          line-height: 24px;
          top: 20px;
          left: 30px;
        }
      }

      .namePlaceholderAct {
        transform: translate(0, -1.2vw) scale(0.8);
        @media (max-width: $breakpoint-md) {
          transform: translate(0, -15px) scale(0.8);
        }
        @media (min-width: $breakpoint-lg) {
          transform: translate(0, -15px) scale(0.8);
        }
      }

      .emailPlaceholderAct {
        transform: translate(0, -1.2vw) scale(0.8);
        @media (max-width: $breakpoint-md) {
          transform: translate(0, -15px) scale(0.8);
        }
        @media (min-width: $breakpoint-lg) {
          transform: translate(0, -15px) scale(0.8);
        }
      }

      .msgPlaceholderAct {
        transform: translate(0, -1.3vw) scale(0.8);
        @media (max-width: $breakpoint-md) {
          transform: translate(0, -25px) scale(0.8);
        }
        @media (min-width: $breakpoint-lg) {
          transform: translate(0, 0px) scale(0.8);
        }
      }

      .msgPlaceholder {
        position: absolute;
        color: $red;
        font-family: $font-sans;
        top: 2.048vw;
        left: 1.7vw;
        transform-origin: top left;
        transition: all 0.3s ease;
        pointer-events: none;
        font-size: 0.964vw;
        line-height: 1.265vw;
        @media (max-width: $breakpoint-md) {
          top: 39px;
          left: 20px;
          font-size: 16px;
          line-height: 24px;
        }
        @media (min-width: $breakpoint-lg) {
          font-size: 16px;
          line-height: 24px;
          top: 13%;
          left: 30px;
        }
      }

      &:focus-within > .namePlaceholder {
        transform: translate(0, -1.2vw) scale(0.8);
        @media (max-width: $breakpoint-md) {
          transform: translate(0, -15px) scale(0.8);
        }
        @media (min-width: $breakpoint-lg) {
          transform: translate(0, -15px) scale(0.8);
        }
      }

      &:focus-within > .emailPlaceholder {
        transform: translate(0, -1.2vw) scale(0.8);
        @media (max-width: $breakpoint-md) {
          transform: translate(0, -15px) scale(0.8);
        }
        @media (min-width: $breakpoint-lg) {
          transform: translate(0, -15px) scale(0.8);
        }
      }

      &:focus-within > .msgPlaceholder {
        transform: translate(0, -1.3vw) scale(0.8);
        @media (max-width: $breakpoint-md) {
          transform: translate(0, -25px) scale(0.8);
        }
        @media (min-width: $breakpoint-lg) {
          transform: translate(0, 0px) scale(0.8);
        }
      }
    }

    input,
    textarea {
      font-family: $font-sans;
      outline: none;
      border: none;
      background: $form-bg;
      padding: 1.386vw 1.807vw 0.723vw 1.807vw;
      border-bottom: 0.12vw solid $red;
      border-radius: 0.301vw 0.301vw 0 0;
      @include FONT-REGULAR;
      @media (max-width: $breakpoint-md) {
        padding: 26px 0 12px 20px;
        border-bottom: 2px solid $red;
        border-radius: 5px 5px 0 0;
      }
      @media (min-width: $breakpoint-lg) {
        padding: 23px 30px 12px 30px;
        border-bottom: 2px solid $red;
        border-radius: 5px 5px 0 0;
      }
    }

    textarea {
      width: 100%;
      min-height: 9.337vw;
      resize: none;
      padding: 2.048vw 0 0 1.807vw;
      @media (max-width: $breakpoint-md) {
        min-height: 222px;
        padding: 39px 0 0 21px;
      }
      @media (min-width: $breakpoint-lg) {
        padding: 2.048vw 0 0 30px;
      }
    }

    input {
      width: 100%;
    }

    button {
      margin-left: auto;
      outline: none;
      border: none;
      cursor: pointer;
      padding: 1.205vw 3.735vw;
      font-weight: 700;
      transform: translateY(30px);
      transition: transform 0.8s ease;

      &[disabled] {
        opacity: 0.5;
        cursor: not-allowed;
      }

      @media (max-width: $breakpoint-md) {
        margin: auto;
        width: 100%;
        justify-content: center;
        padding: 20px 62px;
      }
      @media (min-width: $breakpoint-lg) {
        padding: 20px 53px;
      }
    }
  }
}
