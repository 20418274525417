@import '../variables.scss';

.galleryWrapper {
  position: relative;
  padding-bottom: 9.036vw;
  overflow-x: hidden;
  scroll-margin-top: 75px;
  @media (max-width: $breakpoint-sm) {
    padding-bottom: 66px;
  }
  @media (min-width: $breakpoint-lg) {
    padding-bottom: 150px;
  }

  &.visible {
    .contentWrapper {
      opacity: 1;
    }
  }

  &.grey {
    background: $tabs-inactive;
  }

  .contentWrapper {
    position: relative;
    opacity: 0;
    transition: opacity 0.8s ease;

    .slider_arrows {
      z-index: 2;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 100%;
      padding: 0 6.024vw;
      display: flex;
      justify-content: space-between;
      @media (min-width: $breakpoint-lg) {
        padding: 0 calc((100% - 1600px) / 2);
      }

      &.popupArrow {
        z-index: 10001;

        span {
          transform: scale(1.5);
          @media (min-width: $breakpoint-lg) {
            transform: scale(2);
          }
        }
      }

      span {
        position: relative;
        display: block;
        z-index: 5;
        cursor: pointer;
      }

      img {
        width: 1.084vw;
        height: 1.205vw;
        @media (max-width: $breakpoint-md) {
          width: 18px;
          height: 20px;
        }
        @media (min-width: $breakpoint-lg) {
          width: 18px;
          height: 20px;
        }
      }
    }

    .slider_arrow {
      background: none;
      border: none;
      cursor: pointer;
    }

    .carouselWrapperInactive {
      width: 100%;

      :global(.slick-dots) li button {
        display: none;
      }
    }
    .carouselWrapper {
      width: 100%;

      &.desktop {
        .slideWrapper {
          display: grid;
          grid-template-columns: repeat(6, 11.687vw);
          grid-template-rows: repeat(2, 11.687vw);
          grid-gap: 0.602vw;
          margin: 0.602vw auto;
          width: max-content;
          @media (min-width: $breakpoint-lg) {
            grid-template-columns: repeat(6, 194px);
            grid-template-rows: repeat(2, 194px);
            grid-gap: 10px;
            margin: 10px auto;
          }

          .image {
            overflow: hidden;
            border-radius: 0.301vw;
            @media (min-width: $breakpoint-lg) {
              border-radius: 5px;
            }

            &:nth-of-type(8n + 1) {
              grid-row: 1 / span 1;
              grid-column: 1 / span 1;
            }

            &:nth-of-type(8n + 2) {
              grid-row: 2 / span 1;
              grid-column: 1 / span 1;
            }

            &:nth-of-type(8n + 3) {
              grid-row: 1 / span 2;
              grid-column: 2 / span 2;
            }

            &:nth-of-type(8n + 4) {
              grid-row: 1 / span 2;
              grid-column: 4 / span 1;
            }

            &:nth-of-type(8n + 5) {
              grid-row: 1 / span 1;
              grid-column: 5 / span 1;
            }

            &:nth-of-type(8n + 6) {
              grid-row: 2 / span 1;
              grid-column: 5 / span 1;
            }

            &:nth-of-type(8n + 7) {
              grid-row: 1 / span 1;
              grid-column: 6 / span 1;
            }

            &:nth-of-type(8n) {
              grid-row: 2 / span 1;
              grid-column: 6 / span 1;
            }

            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
              transition: transform 0.3s ease;
              transform-origin: 50% 50%;
              cursor: pointer;

              &:hover {
                transform: scale(1.2);
              }
            }
          }
        }
      }

      &.mobile {
        .slideWrapper {
          width: 100%;

          img {
            width: 260px;
            height: 150px;
            object-fit: cover;
            transition: transform 0.3s ease;
            transform-origin: 50% 50%;
            margin: auto;
            border-radius: 5px;
            pointer-events: none;

            &:hover {
              transform: scale(1.2);
            }
          }
        }
      }
    }
  }

  :global(.slick-dots) {
    bottom: -4.217vw;
    z-index: 99;
    @media (min-width: $breakpoint-lg) {
      bottom: -3.217vw;
    }
    @media (max-width: $breakpoint-md) {
      bottom: -50px;
      transform: translateY(-50%);
    }

    li {
      margin: 0 0.542vw;

      &:has(:focus-visible) {
        @include OUTLINE;
      }

      button {
        @media (max-width: $breakpoint-md) {
          width: 8px;
          height: 8px;
          padding: 6px;
          margin: 0;
        }
      }

      @media (max-width: $breakpoint-md) {
        margin: 0 3px;
        width: 12px;
        height: 12px;
      }

      ::before {
        content: '';
        width: 0.41vw;
        height: 0.41vw;
        color: $red;
        background: transparent;
        border: 0.181vw solid $red;
        border-radius: 50%;
        @media (max-width: $breakpoint-md) {
          width: 9px;
          height: 9px;
          border: 2px solid $red;
        }
        @media (min-width: $breakpoint-lg) {
          width: 9px;
          height: 9px;
          border: 3px solid $red;
        }
      }
    }

    :global(.slick-active) {
      ::before {
        content: '';
        width: 0.41vw;
        height: 0.41vw;
        color: $red;
        background: $red;
        border-radius: 50%;
        @media (max-width: $breakpoint-md) {
          width: 9px;
          height: 9px;
        }
      }
    }
  }

  .popup {
    position: fixed;
    z-index: 10000;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    .overlay {
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 1;
      background: $popup-overlay;

      .closeBtn {
        position: absolute;
        right: 3.012vw;
        top: 4.5vw;
        font-family: 'DBHead-Black';
        font-size: 1.205vw;
        color: $black;
        cursor: pointer;
        z-index: 2;
        border: 0.241vw solid $black;
        padding: 0.602vw 0.904vw;
        border-radius: 50px;
        background: transparent;
        @media (min-width: $breakpoint-lg) {
          right: 70px;
          top: 70px;
          border: 4px solid $black;
          padding: 10px 15px;
          font-size: 20px;
        }

        &:hover {
          color: $red;
          border: 0.241vw solid $dark-red;
        }
      }
    }

    .content {
      position: absolute;
      left: 50%;
      top: 50%;
      background: transparent;
      transform: translate(-50%, -50%);
      width: fit-content;
      max-width: 50vw;
      height: 33vw;
      z-index: 2;
      box-shadow: 0 0 0.723vw 0.301vw $box-shadow;
      border-radius: 0.602vw;
      overflow: hidden;
      @media (min-width: $breakpoint-lg) {
        max-width: 40vw;
        border-radius: 10px;
        box-shadow: 0 0 12px 5px $box-shadow;
      }

      * {
        border-radius: 0.602vw;
        @media (min-width: $breakpoint-lg) {
          border-radius: 10px;
        }
      }

      img {
        border-radius: 0.602vw;
        object-fit: fill;
        height: 33vw;
        width: 50vw;
        margin: auto;
        @media (min-width: $breakpoint-lg) {
          border-radius: 10px;
          max-width: 40vw;
        }
      }
    }
  }
}

:global {
  .slick-prev:before,
  .slick-next:before {
    display: none;
  }
}

.srOnly {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0,0,0,0);
  border: 0;
}