@import './typography.scss';

//colors

$yellow: #f39200;
$yellow-70: #f39200b3;
$blue-70: #1455c0b3;
$black: #000000;
$light-grey: #999999;
$medium-grey: #858379;
$dark-grey: #646973;
$form-bg: #282d370d;
$light-green: #9fd45f;
$dark-green: #63a615;
$red: #ec0016;
$dark-red: #d30014;
$burger-menu: #272d37;
$white: #ffffff;
$tabs-inactive: #f5f5f5;
$tabs-borders: #cdcfcf;
$map-scrollbar: #d9d8d8;
$map-checkboxes: #d3d3d3;
$title-variation-2: #5eadb7;
$title-variation-2--darker: #006A96;
$box-shadow: rgb(0 0 0 / 42%);
$popup-overlay: rgba(255, 255, 255, 0.8);

//breakpoints

$breakpoint-sm: 560px;
$breakpoint-md: 960px;
$breakpoint-lg: 1660px;

//fonts

$font-fallback: 'Helvetica Neue', Helvetica, Arial, sans-serif;

$font-head: 'DBHead-Regular', $font-fallback;
$font-head-black: 'DBHead-Black', $font-fallback;
$font-sans: 'DBSans-Regular', $font-fallback;
$font-sans-bold: 'DBSans-Bold', $font-fallback;

@mixin FONT-H1() {
  font-family: $font-head-black;
  font-size: 4.217vw;
  color: $black;
  line-height: 3.47vw;
  @media (max-width: $breakpoint-md) {
    font-size: 48px;
    line-height: 55px;
  }
}

@mixin FONT-H2() {
  font-family: $font-head-black;
  font-size: 2.892vw;
  color: $black;
  letter-spacing: 0px;
  line-height: 3.855vw;
  @media (max-width: $breakpoint-md) {
    font-size: 28px;
    line-height: 36px;
  }
  @media (min-width: $breakpoint-lg) {
    font-size: 48px;
    line-height: 60px;
  }
}

@mixin FONT-H3() {
  font-family: $font-head-black;
  font-size: 2.048vw;
  color: $black;
  letter-spacing: 0px;
  line-height: 2.41vw;
  @media (max-width: $breakpoint-md) {
    font-size: 34px;
    line-height: 40px;
  }
}

@mixin FONT-H6() {
  font-family: $font-head;
  color: $black;
  letter-spacing: 0px;
  font-size: 1.446vw;
  line-height: 2.048vw;
  @media (max-width: $breakpoint-md) {
    font-size: 24px;
    line-height: 34px;
  }
}

@mixin FONT-REGULAR() {
  font-family: $font-sans;
  font-size: 0.964vw;
  color: $black;
  letter-spacing: 0px;
  line-height: 1.446vw;
  @media (max-width: $breakpoint-md) {
    font-size: 16px;
    line-height: 24px;
  }
  @media (min-width: $breakpoint-lg) {
    font-size: 16px;
    line-height: 24px;
  }
}

@mixin FONT-BOLD() {
  font-family: $font-sans-bold;
  font-size: 0.964vw;
  color: $black;
  letter-spacing: 0px;
  line-height: 1.446vw;
  @media (max-width: $breakpoint-md) {
    font-size: 16px;
    line-height: 24px;
  }
  @media (min-width: $breakpoint-lg) {
    font-size: 16px;
    line-height: 24px;
  }
}

@mixin FONT-DBHead-BlackItalic() {
  font-family: 'DBHead', $font-fallback;
  font-weight: 900;
  font-style: italic;
}

@mixin FONT-DBSans-Italic() {
  font-family: 'DBSans', $font-fallback;
  font-weight: normal;
  font-style: italic;
}

@mixin default_p() {
  // p, div {
  @include FONT-REGULAR;

  b,
  strong {
    @include FONT-BOLD;
  }
  // }
}

@mixin OUTLINE() {
  outline: black dotted 3px;
  outline-offset:2px;
  box-shadow:0 0 0 6px #fff;
}

//buttons

.button {
  padding: 1.205vw;
  border-radius: 0.301vw;
  display: flex;
  align-items: center;
  width: max-content;
  font-family: $font-sans-bold;
  font-size: 0.964vw;
  line-height: 1.446vw;
  @media (max-width: $breakpoint-md) {
    padding: 20px;
    border-radius: 5px;
    font-size: 16px;
    line-height: 24px;
  }
  @media (min-width: $breakpoint-lg) {
    font-size: 16px;
    line-height: 24px;
    border-radius: 5px;
    padding: 20px;
  }

  &.primary {
    background: $red;
    color: $white;

    &:hover {
      background: $dark-red;
    }
  }

  &.outline {
    border: 1px solid $black;

    &:hover {
      border: 1px solid $dark-red;
      color: $dark-red;
    }
  }

  &:focus {
    @include OUTLINE;
  }
}
