@import '../variables.scss';

.interactiveMapWrapper {
  display: flex;
  justify-content: center;
  position: relative;
  padding: 3.373vw;
  opacity: 0;
  transition: opacity 0.8s ease;
  max-width: 100%;
  margin: auto;
  scroll-margin-top: 75px;
  @media (max-width: $breakpoint-md) {
    padding: 0;
  }
  @media (min-width: $breakpoint-lg) {
    padding: 56px;
    max-width: 1660px;
  }
  &.visible {
    opacity: 1;
  }
  :global(.H_l_anchor.H_l_horizontal) {
    display: none;
  }
  :global(.H_ib_tail) {
    display: none;
  }
  :global(.H_ib_body) {
    top: 0;
    right: auto;
    left: 0;
    transform: translateX(-50%);
    height: max-content;
  }
  :global(.H_ib_content) {
    p {
      white-space: pre-line;
    }
  }
  :global(.information) {
    margin: 1.265vw 0 1.145vw 0;
    @media (max-width: $breakpoint-md) {
      margin: 21px 0 19px 0;
    }
    @media (min-width: $breakpoint-lg) {
      margin: 21px 0 19px 0;
    }
  }


  .srOnly {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0,0,0,0);
    border: 0;
  }

  .desktop {
    display: flex;
    justify-content: center;
    max-width: 100%;
  }
  .mobile {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    .filters {
      width: 100%;
      h4 {
        text-align: center;
      }
      .filtersButton {
        margin: 25px;
        border: 1px solid #e9e9e9;
        border-radius: 5px;
        background: none;
        .arrow {
          padding: 21px 0 15px 15px;
          flex: 1 1 80%;
          &:before {
            background-image: url('../../../public/images/chevron-blue.svg');
            height: 8px;
            width: 13px;
            content: '';
            display: inline-block;
            background-size: cover;
            margin: 0 5px 3px 0;
            transition: all 0.5s ease;
          }
        }
        .buttonMap {
          padding: 21px 20px;
          margin: 0;
          flex: 1 1 10%;
          border-left: 1px solid #e9e9e9;
          display: flex;
          justify-content: center;
          align-items: center;
          &.active {
            border-bottom: 2px solid #5eadb7;
          }
          img {
            margin: 0;
            width: 30px;
            height: 30px;
          }
        }
      }
      .filteredLocations {
        margin: 0 25px;
        max-height: 650px;
        .filterWrapper {
          .filter {
            &:before {
              width: 16px;
              min-width: 16px;
              height: 16px;
              margin-right: 16px;
            }
          }
        }
      }
    }
    .mapWrapper {
      width: 100%;
      max-width: 100%;
      display: block;
      position: static;
      top: 0;
      left: 0;
      &.invisibleMap {
        position: absolute;
        top: -100%;
        left: -100%;
        visibility: hidden;
      }
    }
  }
  .info {
    max-width: 15.723vw;
    max-width: 20vw;
    border-radius: 0.482vw;
    padding: 1.446vw;
    position: relative;
    @media (max-width: $breakpoint-md) {
      border-radius: 8px;
      padding: 24px;
      min-width: 261px;
    }
    @media (min-width: $breakpoint-lg) {
      border-radius: 8px;
      padding: 24px;
    }
    .H_ib_close {
      cursor: pointer;
      width: 0.602vw;
      position: absolute;
      top: 1.205vw;
      right: 1.205vw;
      @media (max-width: $breakpoint-md) {
        width: 10px;
        top: 20px;
        right: 20px;
      }
      @media (min-width: $breakpoint-lg) {
        width: 10px;
        top: 20px;
        right: 20px;
      }
    }
    h5 {
      font-family: $font-head-black;
      font-size: 0.964vw;
      line-height: 2.41vw;
      margin: 0 0 0.542vw 0;
      @media (max-width: $breakpoint-md) {
        font-size: 16px;
        line-height: 40px;
        margin: 0 0 9px 0;
      }
      @media (min-width: $breakpoint-lg) {
        font-size: 16px;
        line-height: 40px;
        margin: 0 0 9px 0;
      }
    }
    a {
      overflow-wrap: break-word;
      word-wrap: break-word;
      text-decoration: underline;
      display: flex;
      align-items: center;
      margin: 0 0 0.542vw 0;
      @media (max-width: $breakpoint-md) {
        margin: 0 0 9px 0;
      }
      @media (min-width: $breakpoint-lg) {
        margin: 0 0 9px 0;
      }
      span {
        width: calc(100% - 20px);
      }
      &:before {
        content: '';
        width: 0.843vw;
        height: 0.843vw;
        margin-right: 0.602vw;
        border-radius: 0.241vw;
        background: $map-checkboxes;
        flex-shrink: 0;
        @media (max-width: $breakpoint-md) {
          width: 14px;
          height: 14px;
          margin-right: 10px;
          border-radius: 4px;
        }
        @media (min-width: $breakpoint-lg) {
          width: 14px;
          height: 14px;
          margin-right: 10px;
          border-radius: 4px;
        }
      }
    }
  }
  .filters {
    width: 22.349vw;
    @media (max-width: $breakpoint-md) {
      //min-width: 90%;
    }
    @media (min-width: $breakpoint-lg) {
      //min-width: 19.699vw;
    }
    h4 {
      font-family: $font-head-black;
      font-size: 1.506vw;
      line-height: 1.446vw;
      margin-left: 1.506vw;
      @media (max-width: $breakpoint-md) {
        font-size: 25px;
        line-height: 24px;
        margin-left: 25px;
      }
      @media (min-width: $breakpoint-lg) {
        font-size: 25px;
        line-height: 24px;
        margin-left: 25px;
      }
    }
    .filtersRemoveButton {
      background: none;
      border: none;
      cursor: pointer;
    }
    .filtersButton {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 1.506vw;
      margin-left: 1.506vw;
      cursor: pointer;
      background: none;
      border: none;

      @media (max-width: $breakpoint-md) {
        margin-bottom: 25px;
        margin-left: 25px;
      }
      @media (min-width: $breakpoint-lg) {
        margin-bottom: 25px;
        margin-left: 25px;
      }
      img {
        width: 1.205vw;
        height: 1.205vw;
        margin: 0.602vw 0.602vw 0.602vw 0;
        @media (max-width: $breakpoint-md) {
          width: 20px;
          height: 20px;
          margin: 10px 10px 10px 0;
        }
        @media (min-width: $breakpoint-lg) {
          width: 20px;
          height: 20px;
          margin: 10px 10px 10px 0;
        }
      }
      .arrow {
        font-family: $font-sans-bold;
        margin-left: 0;
        &:before {
          background-image: url('../../../public/images/arrow.svg');
          height: 0.422vw;
          width: 0.783vw;
          content: '';
          display: inline-block;
          background-size: cover;
          margin: auto 0.964vw 0.181vw auto;
          transition: all 0.5s ease;
          @media (min-width: $breakpoint-lg) {
            height: 7px;
            width: 13px;
            margin: auto 16px 3px auto;
          }
        }
        &.arrowRotate {
          &:before {
            transform: rotate(180deg);
          }
        }
      }
    }
    p {
      margin-left: 1.506vw;
      cursor: pointer;
      @media (max-width: $breakpoint-md) {
        margin-left: 25px;
      }
      @media (min-width: $breakpoint-lg) {
        margin-left: 25px;
      }
    }

    .filteredLocations {
      max-height: 750px;
      overflow-y: scroll;
      margin-right: 1.205vw;
      @media (max-width: $breakpoint-md) {
        margin-right: 20px;
      }
      @media (min-width: $breakpoint-lg) {
        margin-right: 20px;
      }
      .filterName {
        @include FONT-BOLD;
      }
      .filterWrapper {
        box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.11);
        border-radius: 0.301vw;
        max-width: 85%;
        margin: 1.446vw auto 1.446vw 1.506vw;
        padding: 0 0.904vw;
        @media (max-width: $breakpoint-md) {
          border-radius: 5px;
          margin: 24px auto 24px 25px;
          padding: 0 15px;
        }
        @media (min-width: $breakpoint-lg) {
          border-radius: 5px;
          margin: 24px auto 24px 25px;
          padding: 0 15px;
        }
        .filtersCategory {
          padding: 0.904vw 0;
          @media (max-width: $breakpoint-md) {
            padding: 15px 0;
          }
          @media (min-width: $breakpoint-lg) {
            padding: 15px 0;
          }
          .filterName {
            padding-bottom: 0.422vw;
            @media (max-width: $breakpoint-md) {
              padding-bottom: 7px;
            }
            @media (min-width: $breakpoint-lg) {
              padding-bottom: 7px;
            }
          }
        }
      }
      .filter {
        font-family: $font-sans;
        font-size: 0.964vw;
        line-height: 1.265vw;
        padding: 0.723vw 0;
        cursor: pointer;
        display: flex;
        align-items: center;
        word-break: break-word;
        background: none;
        border: none;
        text-align: left;

        @media (max-width: $breakpoint-md) {
          padding: 12px 0;
          font-size: 16px;
          line-height: 21px;
        }
        @media (min-width: $breakpoint-lg) {
          padding: 12px 0;
          font-size: 16px;
          line-height: 21px;
        }
        &:before {
          width: 0.964vw;
          min-width: 0.964vw;
          height: 0.964vw;
          border: 2px solid #272d37;
          content: '';
          display: inline-block;
          margin-right: 1vw;
          border-radius: 2px;
        }
        &.activeFilter {
          &:before {
            background-image: url('../../../public/images/check.svg');
            width: 0.964vw;
            min-width: 0.964vw;
            height: 0.964vw;
            content: '';
            display: inline-block;
            background-size: contain;
            background-repeat: no-repeat;
          }
        }
      }
      .location {
        max-width: 85%;
        margin: 1.446vw auto 1.446vw 1.506vw;
        box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.11);
        border-radius: 0.301vw;
        padding: 0.904vw;
        white-space: pre-line;

        a:focus-visible {
          display: block;
          @include OUTLINE;
        }

        @media (max-width: $breakpoint-md) {
          margin: 24px auto 24px 25px;
          border-radius: 5px;
          padding: 15px;
        }
        @media (min-width: $breakpoint-lg) {
          margin: 24px auto 24px 25px;
          border-radius: 5px;
          padding: 15px;
        }
        h5 {
          @include FONT-BOLD;
          margin: 0 auto 0.422vw auto;
          @media (max-width: $breakpoint-md) {
            margin: 0 auto 7px auto;
          }
          @media (min-width: $breakpoint-lg) {
            margin: 0 auto 7px auto;
          }
        }
        p {
          margin: 0 auto 0.422vw 0;
          @media (max-width: $breakpoint-md) {
            margin: 0 auto 7px auto;
          }
          @media (min-width: $breakpoint-lg) {
            margin: 0 auto 7px auto;
          }
        }
      }
    }
    .filteredLocations::-webkit-scrollbar {
      width: 0.361vw;
      @media (max-width: $breakpoint-md) {
        width: 6px;
      }
      @media (min-width: $breakpoint-lg) {
        width: 6px;
      }
    }
    .filteredLocations::-webkit-scrollbar-thumb {
      background-color: $map-scrollbar;
      border-radius: 0.602vw;
      @media (max-width: $breakpoint-md) {
        border-radius: 10px;
      }
      @media (min-width: $breakpoint-lg) {
        border-radius: 10px;
      }
    }
  }
}
